<template>
    <start-layouts>
        <form action="/register" class="main__form">
            <div class="section section_mobile-secondary">
                <div class="section__header">
                    <div class="section__wysiwyg content-center wysiwyg text-center">
                        <h1>Create An Account</h1>
                        <p>Choose your role. It helps us setup the features you need most.</p>
                    </div>
                </div>
                <div class="section__content">
                    <div class="account">
                        <div class="account__create create">
                            <div class="create__list">
                                <div class="row">
                                    <div class="col col_4 col_mob-12">
                                        <div class="create__item create-item">
                                            <label class="create-item__label">
                                                <input v-model="role" v-bind:value="'entrepreneur'" class="create-item__input" type="radio"
                                                    name="creat-checkbox" required>
                                                <span class="create-item__container">
                                                    <span class="create-item__ico">
                                                        <svg class="ico"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20" height="20"
                                                            viewBox="0 0 20 20" fill="none">
                                                            <circle cx="10" cy="10" r="10"
                                                                fill="#00A961" />
                                                            <path fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M15.2167 5.94997C15.5205 6.25371 15.5205 6.74618 15.2167 7.04992L8.80008 13.4666C8.49634 13.7703 8.00388 13.7703 7.70014 13.4666L4.78347 10.5499C4.47973 10.2462 4.47973 9.75372 4.78347 9.44997C5.08721 9.14623 5.57967 9.14623 5.88341 9.44997L8.25011 11.8167L14.1168 5.94997C14.4205 5.64623 14.913 5.64623 15.2167 5.94997Z"
                                                                fill="white" />
                                                        </svg>
                                                    </span>
                                                    <span class="create-item__content">
                                                        <svg class="create-item__image"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="42" height="42"
                                                            viewBox="0 0 42 42" fill="none">
                                                            <path
                                                                d="M17.4529 31.5002H19.2501V22.7502H22.7501V31.5002H24.5474C24.7784 29.3967 25.8511 27.6607 27.5924 25.7654C27.7901 25.5519 29.0484 24.2482 29.1971 24.0627C30.4331 22.5186 31.2078 20.6567 31.4321 18.6916C31.6563 16.7264 31.3209 14.7379 30.4645 12.955C29.6082 11.1721 28.2656 9.66734 26.5915 8.61401C24.9175 7.56068 22.9799 7.00161 21.002 7.00119C19.0241 7.00076 17.0863 7.559 15.4118 8.61162C13.7372 9.66424 12.3941 11.1684 11.5369 12.951C10.6798 14.7335 10.3436 16.7219 10.5669 18.6871C10.7903 20.6523 11.5643 22.5145 12.7996 24.0592C12.9501 24.2464 14.2119 25.5519 14.4061 25.7637C16.1491 27.6607 17.2219 29.3967 17.4529 31.5002ZM17.5001 35.0002V36.7502H24.5001V35.0002H17.5001ZM10.0696 26.2502C8.42145 24.191 7.38852 21.708 7.08983 19.0874C6.79113 16.4668 7.23881 13.8151 8.38131 11.4378C9.5238 9.06044 11.3146 7.05423 13.5475 5.65022C15.7803 4.24621 18.3644 3.50153 21.002 3.50195C23.6396 3.50238 26.2234 4.24789 28.4558 5.65261C30.6882 7.05733 32.4784 9.06411 33.6202 11.4418C34.7619 13.8195 35.2087 16.4713 34.9092 19.0918C34.6097 21.7124 33.5759 24.195 31.9271 26.2537C30.8421 27.6047 28.0001 29.7502 28.0001 32.3752V36.7502C28.0001 37.6785 27.6314 38.5687 26.975 39.2251C26.3186 39.8814 25.4284 40.2502 24.5001 40.2502H17.5001C16.5719 40.2502 15.6816 39.8814 15.0252 39.2251C14.3689 38.5687 14.0001 37.6785 14.0001 36.7502V32.3752C14.0001 29.7502 11.1564 27.6047 10.0696 26.2502Z"
                                                                fill="#00A961" />
                                                        </svg>
                                                        <span class="create-item__title">Entrepreneur</span>
                                                        <span class="create-item__text hide-sm">Those of you creating a new business and tinkerers of innovation</span>
                                                    </span>
                                                    <span class="create-item__text hide-md ps-50">Those of you creating a new business and tinkerers of innovation</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col col_4 col_mob-12">
                                        <div class="create__item create-item">
                                            <label class="create-item__label">
                                                <input v-model="role" v-bind:value="'expert'" class="create-item__input" type="radio"
                                                    name="creat-checkbox" required>
                                                <span class="create-item__container">
                                                    <span class="create-item__ico">
                                                        <svg class="ico"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20" height="20"
                                                            viewBox="0 0 20 20" fill="none">
                                                            <circle cx="10" cy="10" r="10"
                                                                fill="#00A961" />
                                                            <path fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M15.2167 5.94997C15.5205 6.25371 15.5205 6.74618 15.2167 7.04992L8.80008 13.4666C8.49634 13.7703 8.00388 13.7703 7.70014 13.4666L4.78347 10.5499C4.47973 10.2462 4.47973 9.75372 4.78347 9.44997C5.08721 9.14623 5.57967 9.14623 5.88341 9.44997L8.25011 11.8167L14.1168 5.94997C14.4205 5.64623 14.913 5.64623 15.2167 5.94997Z"
                                                                fill="white" />
                                                        </svg>
                                                    </span>
                                                    <span class="create-item__content">
                                                        <svg class="create-item__image"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="42" height="42"
                                                            viewBox="0 0 42 42" fill="none">
                                                            <path
                                                                d="M21 12.2498C24.713 12.2498 28.274 13.7248 30.8995 16.3503C33.525 18.9758 35 22.5368 35 26.2498C35 29.9628 33.525 33.5238 30.8995 36.1493C28.274 38.7748 24.713 40.2498 21 40.2498C17.287 40.2498 13.726 38.7748 11.1005 36.1493C8.475 33.5238 7 29.9628 7 26.2498C7 22.5368 8.475 18.9758 11.1005 16.3503C13.726 13.7248 17.287 12.2498 21 12.2498ZM21 15.7498C18.2152 15.7498 15.5445 16.856 13.5754 18.8252C11.6062 20.7943 10.5 23.465 10.5 26.2498C10.5 29.0346 11.6062 31.7053 13.5754 33.6744C15.5445 35.6435 18.2152 36.7498 21 36.7498C23.7848 36.7498 26.4555 35.6435 28.4246 33.6744C30.3938 31.7053 31.5 29.0346 31.5 26.2498C31.5 23.465 30.3938 20.7943 28.4246 18.8252C26.4555 16.856 23.7848 15.7498 21 15.7498ZM21 18.3748L23.3153 23.0648L28.49 23.8173L24.745 27.466L25.6287 32.6215L21 30.1873L16.3712 32.6198L17.255 27.466L13.51 23.8155L18.6847 23.063L21 18.3748ZM31.5 3.4998V8.7498L29.1147 10.7413C27.1354 9.70282 24.9744 9.05575 22.75 8.83555V3.4998H31.5ZM19.25 3.49805V8.83555C17.0264 9.05543 14.8659 9.70189 12.887 10.7395L10.5 8.7498V3.4998L19.25 3.49805Z"
                                                                fill="#00A961" />
                                                        </svg>
                                                        <span class="create-item__title">Expert</span>
                                                        <span class="create-item__text hide-sm">Those of you offering your services to others on the platform</span>
                                                    </span>
                                                    <span class="create-item__text hide-md ps-50">Those of you offering your services to others on the platform</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col col_4 col_mob-12">
                                        <div class="create__item create-item">
                                            <label class="create-item__label">
                                                <input v-model="role" v-bind:value="'investor'" class="create-item__input" type="radio"
                                                    name="creat-checkbox" required>
                                                <span class="create-item__container">
                                                    <span class="create-item__ico">
                                                        <svg class="ico"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20" height="20"
                                                            viewBox="0 0 20 20" fill="none">
                                                            <circle cx="10" cy="10" r="10"
                                                                fill="#00A961" />
                                                            <path fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M15.2167 5.94997C15.5205 6.25371 15.5205 6.74618 15.2167 7.04992L8.80008 13.4666C8.49634 13.7703 8.00388 13.7703 7.70014 13.4666L4.78347 10.5499C4.47973 10.2462 4.47973 9.75372 4.78347 9.44997C5.08721 9.14623 5.57967 9.14623 5.88341 9.44997L8.25011 11.8167L14.1168 5.94997C14.4205 5.64623 14.913 5.64623 15.2167 5.94997Z"
                                                                fill="white" />
                                                        </svg>
                                                    </span>
                                                    <span class="create-item__content">
                                                        <svg class="create-item__image"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="42" height="42"
                                                            viewBox="0 0 42 42" fill="none">
                                                            <path
                                                                d="M12.25 8.75V3.5C12.25 3.03587 12.4344 2.59075 12.7626 2.26256C13.0908 1.93437 13.5359 1.75 14 1.75H28C28.4641 1.75 28.9093 1.93437 29.2374 2.26256C29.5656 2.59075 29.75 3.03587 29.75 3.5V8.75H36.75C37.2141 8.75 37.6592 8.93437 37.9874 9.26256C38.3156 9.59075 38.5 10.0359 38.5 10.5V35C38.5 35.4641 38.3156 35.9092 37.9874 36.2374C37.6592 36.5656 37.2141 36.75 36.75 36.75H5.25C4.78587 36.75 4.34075 36.5656 4.01256 36.2374C3.68437 35.9092 3.5 35.4641 3.5 35V10.5C3.5 10.0359 3.68437 9.59075 4.01256 9.26256C4.34075 8.93437 4.78587 8.75 5.25 8.75H12.25ZM26.25 12.25H15.75V33.25H26.25V12.25ZM12.25 12.25H7V33.25H12.25V12.25ZM29.75 12.25V33.25H35V12.25H29.75ZM15.75 5.25V8.75H26.25V5.25H15.75Z"
                                                                fill="#00A961" />
                                                        </svg>
                                                        <span class="create-item__title">Investor</span>
                                                        <span class="create-item__text hide-sm">Those of you looking to network, organize, collaborate with and/or find start-ups to invest into</span>
                                                    </span>
                                                    <span class="create-item__text hide-md ps-50">Those of you looking to network, organize, collaborate with and/or find start-ups to invest into</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section__footer">
                    <div class="form-field align-center">
                        <div class="form-field__content">
                            <button type="submit" @click.prevent="makeAccount" class="btn btn_primary">Make an account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </start-layouts>
</template>

<script>
import StartLayouts from "../layouts/Start.vue"
import store from '../store'

export default {
  name: "AccountCreate",
  components: {
    StartLayouts,
  },
  props: {},
  data() {
    return {
      role: '',
    }
  },
  beforeMount() {
    localStorage.removeItem("inv_company_id");
    localStorage.removeItem("inv_invitation_id");
    localStorage.removeItem("inv_uid");
    localStorage.removeItem("inv_reg_role");
  },
  methods: {
    makeAccount: function() {
      if(this.role.length > 0){
        store.dispatch('USER_REGISTER_ROLE', this.role);
      }
    }
  }
}
</script>
<style scoped>
.account__create .create-item__container {
    display: block;
}
.account__create .create-item__text {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    max-width: 144px;
    white-space: normal;
}
.account__create .create-item__content:after {
    width: 0;
}
.account__create .create-item__text.ps-50 {
    max-width: 100%;
    padding-left: 52px;
    text-align: center;
}
@media screen and (max-width: 767px) {
    .hide-sm {display: none;}
    .hide-md {display: block;}
}
@media screen and (min-width: 768px) {
    .hide-md {display: none;}
}
</style>
